export default {
  banner: [
    {
      id: 9,
      titulo: '',
      descripcion: null,
      url: '/assets/images/banner/9',
      sources: [
        { width: 1200, types: ['webp', 'jpg', 'png'], portrait: false },
        { width: 840, types: ['webp', 'jpg', 'png'], portrait: false },
        { width: 480, types: ['webp', 'jpg', 'png'], portrait: true },
        { width: 560, types: ['webp', 'jpg', 'png'], portrait: true },
        { width: 320, types: ['webp', 'jpg', 'png'], portrait: true },
      ],
    },
    {
      id: 8,
      titulo: '',
      descripcion: null,
      url: '/assets/images/banner/8',
      sources: [
        { width: 1200, types: ['webp', 'jpg', 'png'], portrait: false },
        { width: 840, types: ['webp', 'jpg', 'png'], portrait: false },
        { width: 480, types: ['webp', 'jpg', 'png'], portrait: true },
        { width: 560, types: ['webp', 'jpg', 'png'], portrait: true },
        { width: 320, types: ['webp', 'jpg', 'png'], portrait: true },
      ],
    },
    {
      id: 7,
      titulo: '',
      descripcion: null,
      url: '/assets/images/banner/7',
      sources: [
        { width: 1200, types: ['webp', 'jpg'], portrait: false },
        { width: 840, types: ['webp', 'jpg'], portrait: false },
        { width: 480, types: ['webp', 'jpg'], portrait: false },
        { width: 560, types: ['webp', 'jpg'], portrait: true },
        { width: 320, types: ['webp', 'jpg'], portrait: true },
      ],
    },
    {
      id: 1,
      titulo: '',
      descripcion: null,
      url: '/assets/images/banner/1',
      sources: [
        { width: 1200, types: ['webp', 'jpg'], portrait: false },
        { width: 840, types: ['webp', 'jpg'], portrait: false },
        { width: 480, types: ['webp', 'jpg'], portrait: false },
        { width: 560, types: ['webp', 'jpg'], portrait: true },
        { width: 320, types: ['webp', 'jpg'], portrait: true },
      ],
    },
    {
      id: 2,
      titulo: '',
      descripcion: null,
      url: '/assets/images/banner/2',
      sources: [
        { width: 1200, types: ['webp', 'jpg'], portrait: false },
        { width: 840, types: ['webp', 'jpg'], portrait: false },
        { width: 480, types: ['webp', 'jpg'], portrait: false },
        { width: 560, types: ['webp', 'jpg'], portrait: true },
        { width: 320, types: ['webp', 'jpg'], portrait: true },
      ],
    },
    {
      id: 3,
      titulo: '',
      descripcion: null,
      url: '/assets/images/banner/3',
      sources: [
        { width: 1200, types: ['webp', 'jpg'], portrait: false },
        { width: 840, types: ['webp', 'jpg'], portrait: false },
        { width: 480, types: ['webp', 'jpg'], portrait: false },
        { width: 560, types: ['webp', 'jpg'], portrait: true },
        { width: 320, types: ['webp', 'jpg'], portrait: true },
      ],
    },
    {
      id: 4,
      titulo: '',
      descripcion: null,
      url: '/assets/images/banner/4',
      sources: [
        { width: 1200, types: ['webp', 'jpg'], portrait: false },
        { width: 840, types: ['webp', 'jpg'], portrait: false },
        { width: 480, types: ['webp', 'jpg'], portrait: false },
        { width: 560, types: ['webp', 'jpg'], portrait: true },
        { width: 320, types: ['webp', 'jpg'], portrait: true },
      ],
    },
    {
      id: 6,
      titulo: '',
      descripcion: null,
      url: '/assets/images/banner/6',
      sources: [
        { width: 1200, types: ['webp', 'jpg'], portrait: false },
        { width: 840, types: ['webp', 'jpg'], portrait: false },
        { width: 480, types: ['webp', 'jpg'], portrait: false },
        { width: 560, types: ['webp', 'jpg'], portrait: true },
        { width: 320, types: ['webp', 'jpg'], portrait: true },
      ],
    }
  ],
  contacto: {
    correo: 'servicios@pmm.com.mx',
    horario: 'De 9:00 AM a 19:00 PM Horario Centro',
    telefono: '800-0000766',
    telefono_whatsapp: '+526692705424',
    whatsapp: '1',
  },
  horarios: [

    { inicio: { dia: 2, hora: 9}, fin: { dia: 7, hora: 19 } }
  ],
  redesSociales: [
    {
      url: 'https://www.facebook.com/PmmMexico',
      title: 'Facebook',
      icon: 'facebook',
    },
    {
      url: 'https://www.instagram.com/pmmmexico',
      title: 'Instagram',
      icon: 'instagram',
    },
    {
      url: 'https://www.linkedin.com/company/pmmmexico',
      title: 'LinkedIn',
      icon: 'linkedin',
    },
  ],
  sitios: [
    {
      titulo: 'En tu empresa',
      descripcion: 'Genera tus guias desde tu oficina.',
      link: 'http://www.pmmentuempresa.com',
      icon: 'suitcase',
    },
    {
      titulo: 'Prepago',
      descripcion: 'Genera tus guias prepago en linea.',
      link: 'https://pmm.com.mx/prepago',
      icon: 'money',
    }
  ],
  servicios: [
    {
      id: 1,
      titulo: 'services.home_delivery',
      descripcion: 'services.home_delivery_sum',
      imagen: {
        url: '/assets/images/servicios/1',
        sources: [
          { width: 320, types: ['webp', 'jpg'], portrait: false },
        ],
      },
    },
    {
      id: 2,
      titulo: 'services.acknowledgment_of_receipt',
      descripcion: 'services.acknowledgment_of_receipt_sum',
      imagen: {
        url: '/assets/images/servicios/2',
        sources: [
          { width: 320, types: ['webp', 'jpg'], portrait: false },
        ],
      },
    },
    {
      id: 3,
      titulo: 'services.home_collection',
      descripcion: 'services.home_collection_sum',
      imagen: {
        url: '/assets/images/servicios/3',
        sources: [
          { width: 320, types: ['webp', 'jpg'], portrait: false },
        ],
      },
    },
    {
      id: 5,
      titulo: 'services.service_receivable',
      descripcion: 'services.service_receivable_sum',
      imagen: {
        url: '/assets/images/servicios/5',
        sources: [
          { width: 320, types: ['webp', 'jpg'], portrait: false },
        ],
      },
    },
    {
      id: 6,
      titulo: 'services.service_occurs',
      descripcion: 'services.service_occurs_sum',
      imagen: {
        url: '/assets/images/servicios/6',
        sources: [
          { width: 320, types: ['webp', 'jpg'], portrait: false },
        ],
      },
    },
    {
      id: 4,
      titulo: 'services.declared_value',
      descripcion: 'services.declared_value_sum',
      imagen: {
        url: '/assets/images/servicios/4',
        sources: [
          { width: 320, types: ['webp', 'jpg'], portrait: false },
        ],
      },
    },
    {
      id: 8,
      titulo: 'services.return_of_evidence',
      descripcion: 'services.return_of_evidence_sum',
      imagen: {
        url: '/assets/images/servicios/8',
        sources: [
          { width: 320, types: ['webp', 'jpg'], portrait: false },
        ],
      },
    },
    {
      id: 7,
      titulo: 'services.delivery_in_detail',
      descripcion: 'services.delivery_in_detail_sum',
      imagen: {
        url: '/assets/images/servicios/7',
        sources: [
          { width: 320, types: ['webp', 'jpg'], portrait: false },
        ],
      },
    },
  ],
  config: {
    telefono: '800-0000766',
    horario: 'De 9:00 AM a 19:00 PM Horario Centro',
    correo: 'servicios@pmm.com.mx',
    minbusqueda: 13,
    maxbusqueda: 20,
  },
};
